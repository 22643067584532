<template>
  <div class='py-8 relative'>
    <h1 class='text-2xl mb-16' v-if='hasBroker'>
      {{ brokerName }}
    </h1>
    <div class='mb-8' v-else>
      <label class='form-label'>증권사명</label>
      <select v-model='selectedBrokerId' class='form-select'>
        <option v-for='broker in brokers' :key='`broker-${broker.id}`' :value='broker.id'>
          {{ broker.name }}
        </option>
      </select>
    </div>
    <div class='mb-8'>
      <label class='form-label'>메모</label>
      <textarea
        v-model='drilldownAggregateBrokerNote'
        rows='3'
        class='form-input w-full'></textarea>
    </div>

    <div class='mb-8'>
      <label class='form-label'>{{ drilldownAggregateAccount.contact_email }} 이메일</label>
      <input type='text' class='form-input' v-model='drilldownAggregateBrokerContactEmail' />
    </div>
    <div class='mb-8'>
      <label class='form-label'>{{ drilldownAggregateAccount.contact_number }} 연락처</label>
      <input type='text' class='form-input' v-model='drilldownAggregateBrokerContactNumber' />
    </div>

    <div class='mb-8'>
      <label class='form-label'>{{ drilldownAggregateAccount.account_type }} 계좌코드</label>
      <input type='text' class='form-input' v-model='drilldownAggregateBrokerAccount' />
    </div>

    <div class='mb-8'>
      <label class='form-label'>{{ drilldownVentureAccount.account_type }} 계좌코드</label>
      <input type='text' class='form-input' v-model='drilldownVentureBrokerAccount' />
    </div>

    <div class='mb-8'>
      <label class='form-label'>{{ drilldownHighYieldAccount.account_type }} 계좌코드</label>
      <input type='text' class='form-input' v-model='drilldownHighYieldBrokerAccount' />
    </div>

    <div class='mb-8'>
      <label class='form-label'>{{ drilldownSelfAccount.account_type }} 계좌코드</label>
      <input type='text' class='form-input' v-model='drilldownSelfBrokerAccount' />
    </div>
    <portal to='sidepanel-footer'>
      <div class='w-full bg-gray-100 pl-2 lg:pl-16 py-4 text-sm shadow-lg'>
        <button
          class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
          style='background-color: #223645;'
          @click='submitUpdateAccounts'>
          저장
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields }  from 'vuex-map-fields'

export default {
  name: 'BrokerAccountDetails',
  components: {
  },
  data () {
    return {
      selectedBrokerId: 0,
    }
  },
  watch: {
    selectedBrokerId: {
      handler: function () {
        this.drilldownAggregateAccount.broker_id = this.selectedBrokerId
        this.drilldownVentureAccount.broker_id = this.selectedBrokerId
        this.drilldownHighYieldAccount.broker_id = this.selectedBrokerId
        this.drilldownSelfAccount.broker_id = this.selectedBrokerId
      }
    },
    drilldownAggregateBrokerNote: {
      handler: function () {
        // update the rest
        this.drilldownVentureBrokerNote = this.drilldownAggregateBrokerNote
        this.drilldownHighYieldBrokerNote = this.drilldownAggregateBrokerNote
        this.drilldownSelfBrokerNote = this.drilldownAggregateBrokerNote
      }
    },
    drilldownAggregateBrokerContactEmail: {
      handler: function () {
        this.drilldownVentureBrokerContactEmail = this.drilldownAggregateBrokerContactEmail
        this.drilldownHighYieldBrokerContactEmail = this.drilldownAggregateBrokerContactEmail
        this.drilldownSelfBrokerContactEmail = this.drilldownAggregateBrokerContactEmail
      }
    },
    drilldownAggregateBrokerContactNumber: {
      handler: function () {
        this.drilldownVentureBrokerContactNumber = this.drilldownAggregateBrokerContactNumber
        this.drilldownHighYieldBrokerContactNumber = this.drilldownAggregateBrokerContactNumber
        this.drilldownSelfBrokerContactNumber = this.drilldownAggregateBrokerContactNumber
      }
    },
  },
  computed: {
    brokerName () {
      return this.drilldownBroker.name
    },
    ...mapState('brokers', [
      'brokers',
    ]),
    ...mapFields('brokerAccounts', {
      drilldownBroker: 'drilldownBroker',
      drilldownAggregateAccount: 'drilldownAggregateAccount',
      drilldownVentureAccount: 'drilldownVentureAccount',
      drilldownHighYieldAccount: 'drilldownHighYieldAccount',
      drilldownSelfAccount: 'drilldownSelfAccount',
      drilldownAggregateBrokerAccount: 'drilldownAggregateAccount.broker_account',
      drilldownAggregateBrokerNote: 'drilldownAggregateAccount.note',
      drilldownAggregateBrokerContactEmail: 'drilldownAggregateAccount.contact_email',
      drilldownAggregateBrokerContactNumber: 'drilldownAggregateAccount.contact_number',
      drilldownVentureBrokerAccount: 'drilldownVentureAccount.broker_account',
      drilldownVentureBrokerNote: 'drilldownVentureAccount.note',
      drilldownVentureBrokerContactEmail: 'drilldownVentureAccount.contact_email',
      drilldownVentureBrokerContactNumber: 'drilldownVentureAccount.contact_number',
      drilldownHighYieldBrokerAccount: 'drilldownHighYieldAccount.broker_account',
      drilldownHighYieldBrokerNote: 'drilldownHighYieldAccount.note',
      drilldownHighYieldBrokerContactEmail: 'drilldownHighYieldAccount.contact_email',
      drilldownHighYieldBrokerContactNumber: 'drilldownHighYieldAccount.contact_number',
      drilldownSelfBrokerAccount: 'drilldownSelfAccount.broker_account',
      drilldownSelfBrokerNote: 'drilldownSelfAccount.note',
      drilldownSelfBrokerContactEmail: 'drilldownSelfAccount.contact_email',
      drilldownSelfBrokerContactNumber: 'drilldownSelfAccount.contact_number',
    }),
    hasBroker () {
      return this.drilldownBroker.id > 0
    },
  },
  methods: {
    ...mapActions('entities', [
      'getEntities',
    ]),
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('brokerAccounts', [
      'createUpdateBrokerAccounts',
    ]),
    doneEditing () {
      this.sidepanelClose()
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    submitUpdateAccounts () {
      this.createUpdateBrokerAccounts({ accounts: [this.drilldownAggregateAccount, this.drilldownVentureAccount, this.drilldownHighYieldAccount, this.drilldownSelfAccount]})
    },
  },
  mounted () {
    this.getEntities()
  }
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
